export const processStatusStyles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
    marginTop: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2.5),
  },
  statusIcon: {
    fontSize: 35,
    marginLeft: theme.spacing(0.5),
  },
  successIcon: {
    color: theme.palette.success.main,
  },
  errorIcon: {
    color: theme.palette.error.main,
  },
  // imageContainer: {
  //   display: 'flex',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   marginBottom: theme.spacing(4),
  //   width: '100%',
  //   maxWidth: '400px',
  //   height: 'auto',
  // },
  // dynamicImage: {
  //   width: '100%',
  //   height: 'auto',
  //   objectFit: 'contain',
  // },
})
