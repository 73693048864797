export const fetchAndSetClientData = (
  userIdentifier,
  searchClient,
  dispatch,
  setClientData,
  setError
) => {
  if (userIdentifier) {
    return searchClient(userIdentifier.trim())
      .then((response) => {
        dispatch(setClientData(response?.data))
        setError(false)
      })
      .catch((error) => {
        dispatch(setClientData(null))
        setError(true)
        return error?.status
      })
  }
}
