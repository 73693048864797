import React, { useEffect, useState } from 'react'
import { invoicesHeaderStyles } from './invoices-header.styles'
import { TextInput, ThemeButton } from '../../../../ui'
import { Box, Collapse, InputAdornment } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import FilterListIcon from '@material-ui/icons/FilterList'

import { useIntl } from 'react-intl'
import { FilterInput } from '../../../../ui/filter-input'
import { FilterDateRangeComponent } from '../../../../ui/filter-date-range'
import { useInvoicesContext } from '../../contexts'

const initialFiltersState = {
  _search: '',
  contract: '',
  estado_pago: '',
  fecha_factura_start: null,
  fecha_factura_end: null,
  fecha_vencimiento_start: null,
  fecha_vencimiento_end: null,
  fecha_pago_start: null,
  fecha_pago_end: null,
}

const mockContracts = [
  {
    key: 'ES0237022200011153EGP001',
    value: 'ES0237022200011153EGP001 - BERROCAL, 0003 46 2 A - MADRID, MORALZARZAL (28411)',
  },
]

export const InvoicesHeaderComponent = ({
  setSelectedContracts,
  setSelectedInvoices,
  setContracts,
  contracts,
}) => {
  const intl = useIntl()
  const { formatMessage } = intl

  const [filtersOpen, setFiltersOpen] = useState(false)
  const [formState, setFormState] = useState(initialFiltersState)
  const [estadosPago, setEstadosPago] = useState([])

  const { originalData, filterTableData } = useInvoicesContext()

  const handleChangeForm = (event) => {
    setFormState({ ...formState, [event.target.name]: event.target.value })
  }

  function convertToDate(dateString) {
    const [day, month, year] = dateString.split('/').map(Number)
    return new Date(year, month - 1, day) // Restar 1 al mes porque los meses en Date son 0-indexados
  }

  const applyFilters = () => {
    if (Object.keys(formState).length === 0) {
      filterTableData(originalData)
    } else {
      const filtered_data = originalData.filter((invoice) => {
        let isMatch = true

        for (const key in formState) {
          if (!formState[key] && key !== 'contract') continue

          switch (key) {
            case 'fecha_factura_start':
              if (invoice.fecha_factura && convertToDate(invoice.fecha_factura) < formState[key]) {
                isMatch = false
              }
              break
            case 'fecha_factura_end':
              if (invoice.fecha_factura && convertToDate(invoice.fecha_factura) > formState[key]) {
                isMatch = false
              }
              break
            case 'fecha_vencimiento_start':
              if (
                invoice.fecha_vencimiento &&
                convertToDate(invoice.fecha_vencimiento) < formState[key]
              ) {
                isMatch = false
              }
              break
            case 'fecha_vencimiento_end':
              if (
                invoice.fecha_vencimiento &&
                convertToDate(invoice.fecha_vencimiento) > formState[key]
              ) {
                isMatch = false
              }
              break
            case 'fecha_pago_start':
              if (invoice.fecha_pago && convertToDate(invoice.fecha_pago) < formState[key]) {
                isMatch = false
              }
              break
            case 'fecha_pago_end':
              if (invoice.fecha_pago && convertToDate(invoice.fecha_pago) > formState[key]) {
                isMatch = false
              }
              break
            case '_search':
              const localizedTipoDocumento = formatMessage({
                id: `pages.invoices.table.values.tipo_documento.${invoice.tipo_documento?.toLowerCase()}`,
              }).toLowerCase()
              if (
                !invoice.factura.toLowerCase().includes(formState[key].toLowerCase()) &&
                !invoice.num_pedido?.toLowerCase().includes(formState[key].toLowerCase()) &&
                !localizedTipoDocumento.includes(formState[key].toLowerCase())
              ) {
                isMatch = false
              }
              break
            case 'contract':
              // Control the state when the user selects 'all' value
              setSelectedContracts(formState[key] === '' ? contracts : [formState[key]])
              setSelectedInvoices([])
              if (invoice.contrato !== formState[key] && formState[key] !== '') {
                isMatch = false
              }
              break
            case 'estado_pago':
              if (invoice.estado_pago !== formState[key]) {
                isMatch = false
              }
              break
            default:
              break
          }

          if (!isMatch) break
        }

        return isMatch
      })
      filterTableData(filtered_data)
    }
  }

  const getContractsAndEstadosPago = () => {
    const tmp_contracts = []
    const tmp_estadoPago = []
    for (let i = 0; i < originalData.length; i++) {
      const contract = originalData[i].contrato
      const estadoPago = originalData[i].estado_pago
      if (!tmp_contracts.some((c) => c.key === contract)) {
        tmp_contracts.push({
          key: contract,
          value: originalData[i].direccion_suministro + ' - ' + contract,
        })
      }
      if (!tmp_estadoPago.some((c) => c.key === estadoPago)) {
        const estado_pago_localized = formatMessage({
          id: `pages.invoices.table.values.estado_pago.${estadoPago?.toLowerCase()}`,
        })
        tmp_estadoPago.push({
          key: estadoPago,
          value: estado_pago_localized,
        })
      }
    }
    setContracts(tmp_contracts)
    if (tmp_contracts.length === 1)
      setFormState((prev) => ({ ...prev, contract: tmp_contracts[0].key }))
    setEstadosPago(tmp_estadoPago?.sort((a, b) => a.value?.localeCompare(b.value)))
    setSelectedContracts(tmp_contracts)
  }

  useEffect(() => {
    applyFilters()
  }, [formState])

  useEffect(() => {
    getContractsAndEstadosPago()
  }, [originalData])

  const handleSubmitForm = (event) => {
    event.preventDefault()
    applyFilters()
  }

  const filterCleanup = () => {
    setFormState(initialFiltersState)
    filterTableData(originalData)
  }

  const handleToggleFilters = () => {
    setFiltersOpen(!filtersOpen)
  }

  return (
    <header style={invoicesHeaderStyles.headerContainer}>
      <div style={invoicesHeaderStyles.searchContainer}>
        <FilterInput
          name={'contract'}
          values={contracts}
          value={formState['contract']}
          onChange={handleChangeForm}
          label={formatMessage({ id: `pages.invoice.table.header.contracts.title` })}
          inlineStyle={{ minWidth: '50%', width: '100%' }}
        />
        <TextInput
          spaceless="true"
          name="_search"
          placeholder={formatMessage({
            id: 'pages.invoice.search.placeholder',
            defaultMessage: 'Buscar factura',
          })}
          value={formState['_search']}
          onChange={handleChangeForm}
          onBlur={handleSubmitForm}
          onKeyDown={(e) => e.keyCode === 13 && handleSubmitForm(e)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          inlineStyle={{ width: '100%' }}
        />
        <ThemeButton
          color="primary"
          onClick={filterCleanup}
          style={{ minWidth: 'max-content', margin: '10px 0' }}
        >
          {formatMessage({ id: `filters.clear.button` })}
        </ThemeButton>
        <ThemeButton
          variant="outlined"
          endIcon={<FilterListIcon />}
          onClick={handleToggleFilters}
          style={{ margin: '10px 0' }}
        >
          {formatMessage({ id: `filters.toggle.button` })}
        </ThemeButton>
      </div>
      <Collapse in={filtersOpen} style={invoicesHeaderStyles.collapseContainer}>
        <Box style={invoicesHeaderStyles.filtersContainer}>
          <FilterDateRangeComponent
            name={'fecha_factura'}
            label={formatMessage({ id: `pages.invoice.table.header.fecha_factura.title` })}
            onChange={handleChangeForm}
            startValue={formState['fecha_factura_start']}
            endValue={formState['fecha_factura_end']}
          />
          <FilterDateRangeComponent
            name={'fecha_vencimiento'}
            label={formatMessage({ id: `pages.invoice.table.header.fecha_vencimiento.title` })}
            onChange={handleChangeForm}
            startValue={formState['fecha_vencimiento_start']}
            endValue={formState['fecha_vencimiento_end']}
          />
          <FilterDateRangeComponent
            name={'fecha_pago'}
            label={formatMessage({ id: `pages.invoice.table.header.fecha_pago.title` })}
            onChange={handleChangeForm}
            startValue={formState['fecha_pago_start']}
            endValue={formState['fecha_pago_end']}
          />
          <FilterInput
            name={'estado_pago'}
            values={estadosPago}
            value={formState['estado_pago']}
            onChange={handleChangeForm}
            label={formatMessage({ id: `pages.invoice.table.columns.estado_pago.title` })}
          />
        </Box>
      </Collapse>
    </header>
  )
}
