import React, { useState, useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Box, CircularProgress, Typography } from '@material-ui/core'

import underMaintenanceImage from '../../resources/images/under_maintenance.svg'
import { useAjaxContext } from '../../core/ajax'

const { REACT_APP_ENVIRONMENT } = process.env
export const OnServerDown = ({ children }) => {
  const { formatMessage } = useIntl()
  const { serverPing } = useAjaxContext()

  const [isLoading, setIsLoading] = useState(true)
  const [isServerAvailable, setIsServerAvailable] = useState(true)
  const [hour, setHour] = useState(false)

  const checkByPass = () => {
    return true // @todo change and validate
    // const queryString = window.location.search;
    // if (queryString) {
    //   const params = new URLSearchParams(queryString);
    //   const bypassParam = params.get('bypass')
    //   if (bypassParam === 'true') {
    //     localStorage.setItem('bypass', "true")
    //     return true;
    //   }
    // }
    // if(localStorage.getItem('bypass')) {
    //   if(localStorage.getItem('bypass') === 'true') return true;
    // }
    // return false;
  }

  const getServerStatus = () => {
    if (checkByPass()) {
      setIsServerAvailable(true)
      setIsLoading(false)
      return
    }
    // setIsServerAvailable(false)
    // setIsLoading(false)
    // serverPing()
    //   .then(({ data }) => {
    //     setIsServerAvailable(data.API_status)
    //     setIsLoading(false)
    //     setHour(data.hora_prevista)
    //   })
    //   .catch(() => {
    //     setIsServerAvailable(false)
    //     setIsLoading(false)
    //   })
  }

  useEffect(() => {
    if (REACT_APP_ENVIRONMENT === 'Local') {
      setIsLoading(false)
    } else {
      getServerStatus()
    }
  }, []) // eslint-disable-line

  return useMemo(
    () =>
      isLoading ? (
        <Box display="flex" height="100vh" justifyContent="center" alignItems="center">
          <CircularProgress size={40} />
        </Box>
      ) : isServerAvailable ? (
        children
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          height="100vh"
          justifyContent="center"
          alignItems="center"
          p="0 40px"
        >
          <Box pb={2}>
            <img src={underMaintenanceImage} alt={'under mantainance'} />
          </Box>
          <Typography variant="h4" gutterBottom>
            {formatMessage({ id: 'global.under_maintenance.title' })}
          </Typography>
          <Typography variant="h6" color="textSecondary">
            {hour
              ? formatMessage({ id: 'global.under_maintenance.subtitle_hour' }, { hour })
              : formatMessage({ id: 'global.under_maintenance.subtitle' })}
          </Typography>
        </Box>
      ),
    [isLoading, isServerAvailable, hour] // eslint-disable-line
  )
}
