import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Box, Typography, withStyles } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import { ThemeButton } from '../../../ui'
import { processStatusStyles } from './process-status.styles'
// import processStatusOK from '../../../resources/images/process-status-OK.jpeg'
// import processStatusKO from '../../../resources/images/process-status-KO.png'

export const ProcessStatusComponent = withStyles(processStatusStyles)(({ classes }) => {
  const { formatMessage } = useIntl()
  const { status } = useParams()
  const history = useHistory()

  const isSuccess = status?.toLowerCase() === 'ok'

  const getDynamicMessage = (key) =>
    formatMessage({ id: `pages.process-status.${key}.${isSuccess ? 'success' : 'error'}` })

  const navigateToModule = (path) => {
    history.push('/' + path)
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.titleContainer}>
        <Typography variant="h4" color="primary">
          {getDynamicMessage('title')}
        </Typography>
        {isSuccess ? (
          <CheckCircleIcon className={`${classes.statusIcon} ${classes.successIcon}`} />
        ) : (
          <CancelIcon className={`${classes.statusIcon} ${classes.errorIcon}`} />
        )}
      </Box>
      <Typography variant="h5" paragraph>
        {getDynamicMessage('subTitle')}
      </Typography>
      <ThemeButton
        style={{ width: '16em' }}
        color="primary"
        onClick={() => navigateToModule('invoices')}
      >
        {formatMessage({ id: 'pages.process-status.btn' })}
      </ThemeButton>
    </Box>
  )
})
