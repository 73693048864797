import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import {
  Box,
  Container,
  Typography,
  withStyles,
  Tab,
  Tabs,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { invoicesStyles } from './invoices.styles'
import {
  InvoicesHeader,
  InvoicesSearch,
  InvoicesTable,
  PaymentPopup,
} from '../../../features/invoices'
import { useSelector } from 'react-redux'
import { selectRole } from '../../../features/profile/slices'
import { SearchInfoComponent } from '../../../features/searchUser/components/search-info'
import { InvoicesContext } from '../../../features/invoices/contexts'
import { selectClient } from '../../../features/searchUser/slices/searchUser.slice'
import { ThemeButton } from '../../../ui'
import { APP_THEME_COLORS } from '../../../core/theme/constants/colors'

export const InvoicesPage = withStyles(invoicesStyles)(({ classes }) => {
  const { formatMessage } = useIntl()
  const role = useSelector(selectRole)
  const clientInfo = useSelector(selectClient)
  const userIsAdmin = role === 'ATR'
  const [currentTab, setCurrentTab] = useState(userIsAdmin ? 'all' : 'pending_payment')
  const [openDialog, setOpenDialog] = useState(false)
  const [enablePayment, setEnablePayment] = useState(false)
  const [contracts, setContracts] = useState([])
  const [selectedInvoices, setSelectedInvoices] = useState([])
  const [showTooltip, setShowTooltip] = useState('')
  const [selectedContracts, setSelectedContracts] = useState([])

  const handleShowTooltip = () => {
    if ((!selectedContracts && contracts.length >= 2) || selectedContracts.length >= 2)
      return setShowTooltip('multiple')
    if (!selectedInvoices.length && enablePayment) return setShowTooltip('selectInvoices')
    setShowTooltip('')
  }

  const handleSelectInvoice = (rowData, checked) => {
    setSelectedInvoices((prev) =>
      checked ? [...prev, rowData] : prev.filter((_row) => _row.factura !== rowData.factura)
    )
  }

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue)
  }

  const handlePayButton = () => {
    if (!enablePayment) {
      setEnablePayment(true)
      return
    }
    setOpenDialog(true)
  }

  useEffect(() => {
    handleShowTooltip()
  }, [selectedContracts, selectedInvoices.length, enablePayment])

  return (
    <InvoicesContext>
      <Box className={classes.root}>
        <Container className={classes.container} maxWidth="lg">
          {role === 'ATR' && (
            <Box mb={2}>
              <InvoicesSearch />
              <SearchInfoComponent />
            </Box>
          )}

          <>
            <Box mb={2}>
              <Typography variant="h4" color="primary" paragraph>
                {formatMessage({ id: 'pages.invoices.title' })}
              </Typography>
            </Box>
            <Box mb={2}>
              <InvoicesHeader
                setSelectedContracts={setSelectedContracts}
                setSelectedInvoices={setSelectedInvoices}
                setContracts={setContracts}
                contracts={contracts}
              />
            </Box>
            <Box mb={2}>
              {role === 'ATR' ? (
                <></>
              ) : (
                <>
                  <Box mb={2}>
                    <Typography>
                      {formatMessage(
                        { id: 'pages.invoices.warning' },
                        {
                          b: (chunks) => <b style={{ color: APP_THEME_COLORS[30] }}>{chunks}</b>,
                        }
                      )}
                    </Typography>
                  </Box>
                  <Box>
                    <Accordion style={{ width: '100%', background: 'rgba(255, 255, 255, 0.7)' }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>
                          {formatMessage({ id: 'pages.invoices.accordion.title' })}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box>
                          <Typography variant="h6" gutterBottom>
                            {formatMessage({ id: 'pages.invoices.accordion.contentTitle' })}
                          </Typography>
                          <List>
                            {[
                              ...(selectedContracts.length > 1
                                ? [
                                    formatMessage({
                                      id: 'pages.invoices.accordion.multipleContractsMessage',
                                    }),
                                  ]
                                : []),
                              ...Array.from({ length: 4 }, (_, index) =>
                                formatMessage({
                                  id: `pages.invoices.accordion.content${index + 1}`,
                                })
                              ),
                            ].map((content, index) => (
                              <ListItem key={index}>{`${index + 1}. ${content}`}</ListItem>
                            ))}
                          </List>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                </>
              )}
              <Box
                className={classes.tabs}
                display="flex"
                justifyContent="space-between"
                alignItems="flex-end"
                marginTop={2}
              >
                {true ? (
                  <Tabs
                    indicatorColor="primary"
                    value={currentTab}
                    onChange={handleChange}
                    TabIndicatorProps={{ className: classes.tabIndicator }}
                  >
                    <Tab
                      label={formatMessage({ id: 'pages.invoices.tab-pending-payment' })}
                      id={`invoices-tab-0`}
                      value="pending_payment"
                    />
                    <Tab
                      label={formatMessage({ id: 'pages.invoices.tab-all' })}
                      id={`invoices-tab-1`}
                      value="all"
                    />
                  </Tabs>
                ) : (
                  <></>
                )}
                <Box display="flex" alignItems="center">
                  {currentTab === 'pending_payment' && !userIsAdmin ? (
                    <>
                      <Tooltip
                        title={
                          showTooltip ? (
                            <span style={{ fontSize: '13px', margin: '0 !important' }}>
                              {formatMessage({ id: `pages.invoices.button.${showTooltip}` })}
                            </span>
                          ) : (
                            ''
                          )
                        }
                      >
                        <span>
                          <ThemeButton
                            variant="outlined"
                            onClick={handlePayButton}
                            style={{ margin: '0px 0px' }}
                            disabled={showTooltip}
                          >
                            {formatMessage({ id: `pages.invoices.button.pay` })}
                          </ThemeButton>
                        </span>
                      </Tooltip>
                    </>
                  ) : (
                    <></>
                  )}
                </Box>
              </Box>
              <InvoicesTable
                currentTab={currentTab}
                handleSelectInvoice={handleSelectInvoice}
                setSelectedInvoices={setSelectedInvoices}
                selectedInvoices={selectedInvoices}
                classes={classes}
                enablePayment={enablePayment}
              />
            </Box>
          </>
        </Container>
      </Box>
      <PaymentPopup
        openDialog={openDialog}
        handleClose={() => setOpenDialog(false)}
        selectedInvoices={selectedInvoices}
      />
    </InvoicesContext>
  )
})
